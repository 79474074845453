/** @jsx jsx */

import PropTypes from "prop-types"
import { Fragment } from "react"
import { jsx, Heading } from "theme-ui"

import theme from "../../gatsby-plugin-theme-ui"
import { ItemsWithCallout } from "../ItemsWithCallouts"
import { allContributors } from "../../hooks/allContributors"
import { RichText } from "../RichText"
import { SocialLinks } from "../SocialLinks"
import {
  PrismicRichTextFieldPropType,
  ContributorSocialLinkPropTypes,
} from "../../utils/propTypes"

const CalloutChildren = ({ roles, biography, ...socialLinks }) => (
  <Fragment>
    <Heading>{roles}</Heading>
    <RichText text={biography} />
    <SocialLinks {...socialLinks} styles={{ "> a:first-of-type": { ml: 0 } }} />
  </Fragment>
)
CalloutChildren.propTypes = {
  roles: PropTypes.string,
  biography: PrismicRichTextFieldPropType,
  socialLinks: PropTypes.shape(ContributorSocialLinkPropTypes),
}

export const Contributors = () => {
  const contributorItems = allContributors().map(contributor => ({
    uid: contributor.uid,
    image: contributor.data.profile_picture,
    name: contributor.data.name,
    callout: <CalloutChildren {...contributor.data} />,
  }))

  return (
    <div
      sx={{
        my: 5,
      }}
    >
      <Heading
        as="h1"
        variant="headingOlive"
        sx={{
          textAlign: ["left", "left", "center"],
          mb: 3,
          ...theme.basePageStyles.contentContainer,
        }}
      >
        Contributors
      </Heading>
      <ItemsWithCallout
        items={contributorItems}
        itemsPerRow={{
          mobilePortrait: 3,
          mobileLandscape: 4,
          tablet: 8,
        }}
        itemStyle={{
          // Note: have to fix the width and height
          // to work around a limitation in Prismic previews
          // where fixed images are always fetched with a default 400px width
          // See https://github.com/prismicio/prismic-gatsby/blob/main/packages/gatsby-plugin-prismic-previews/docs/limitations.md#image-processing
          ".gatsby-image-wrapper": {
            width: "94px",
            height: "94px",
          },
        }}
      />
    </div>
  )
}
