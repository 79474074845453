export const chunk = (array, chunkSize) => {
  return array.reduce((acc, elem, i) => {
    const lastChunkIndex = Math.floor(i / chunkSize)

    return i % chunkSize === 0
      ? [...acc, [elem]]
      : [...acc.slice(0, lastChunkIndex), [...acc[lastChunkIndex], elem]]
  }, [])
}

export const extractPagePathPrefix = pathname => {
  const validPathnameRegex = new RegExp("^/.*/?$")

  if (!pathname.match(validPathnameRegex))
    throw new Error("Invalid pathname supplied!")

  const pagePathSegmentRegex = new RegExp("[^/]+/?$")
  return pathname.replace(pagePathSegmentRegex, "")
}
