/** @jsx jsx */

import PropTypes from "prop-types"
import { jsx } from "theme-ui"

import theme from "../gatsby-plugin-theme-ui"
import { Layout } from "../components/Layout"
import { MediaMatcher } from "../components/MediaMatcher"
import {
  GatsbyImagePropType,
  PrismicPlainTextFieldPropType,
  PrismicRichTextFieldPropType,
  SeoFieldsPropType,
} from "../utils/propTypes"
import { extractBaseSeoFields } from "../utils/seoUtils"
import { RichText } from "../components/RichText"
import { Masthead } from "../components/Masthead"
import { CoreTeam } from "../components/about_page/CoreTeam"
import { Contributors } from "../components/about_page/Contributors"
import { Divider } from "../components/Divider"
import { DividerType } from "../hooks/divider"

export const AboutPageTemplate = ({ data }) => {
  const { title, description, masthead_background } = data.prismicAboutPage.data
  const seoProps = extractBaseSeoFields({
    ...data.prismicAboutPage.data,
    type: data.prismicAboutPage.type,
    uid: data.prismicAboutPage.uid,
  })

  const isMobile = MediaMatcher.useMedia({
    mobilePortrait: true,
    tablet: false,
  })

  return (
    <Layout seoProps={seoProps}>
      <Masthead
        heading={title.text}
        headingStyles={{
          color: "sandYellow",
          mt: ["50px", "50px", 0],
        }}
        showDivider={isMobile}
        dividerType={DividerType.DEFAULT_YELLOW}
        image={masthead_background}
        containerStyles={{
          height: ["200px", "200px", "300px", "300px"],
        }}
      />
      <RichText
        sxOverrides={{
          ...theme.basePageStyles.contentContainer,
        }}
        text={description}
      />
      <Divider
        type={DividerType.SEEDLING}
        styles={{
          ...theme.basePageStyles.contentContainer,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: ["80px", "100px", "100px", "150px"],
          mt: ["20px", "20px", "30px", "50px"],
          mb: [null, null, null, "80px"],
        }}
      />
      <CoreTeam />
      <Contributors />
    </Layout>
  )
}
AboutPageTemplate.propTypes = {
  data: PropTypes.shape({
    prismicAboutPage: PropTypes.shape({
      type: PropTypes.string,
      uid: PropTypes.string,
      data: PropTypes.shape({
        ...SeoFieldsPropType,
        title: PrismicPlainTextFieldPropType,
        description: PrismicRichTextFieldPropType,
        masthead_background: PropTypes.shape({
          gatsbyImageData: GatsbyImagePropType,
        }),
      }),
    }),
  }),
}
