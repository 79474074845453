/* eslint-disable react-hooks/rules-of-hooks */
import { graphql, useStaticQuery } from "gatsby"
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews"

export const coreTeamMembers = () => {
  const staticData = useStaticQuery(
    graphql`
      query CoreTeamMembers {
        allPrismicContributor(
          filter: { data: { is_core_team_member: { eq: true } } }
          sort: { fields: [data___name], order: [ASC] }
        ) {
          edges {
            node {
              ...ContributorBase
              ...ContributorCoreTeamProfilePic
            }
          }
        }
      }
    `
  )

  const { data } = useMergePrismicPreviewData(staticData)
  return data.allPrismicContributor.edges.map(edge => edge.node)
}
