/** @jsx jsx */

import { Fragment } from "react"
import PropTypes from "prop-types"
import { jsx, Heading, Text } from "theme-ui"

import theme from "../../gatsby-plugin-theme-ui"
import {
  ContributorSocialLinkPropTypes,
  PrismicRichTextFieldPropType,
} from "../../utils/propTypes"
import { coreTeamMembers } from "../../hooks/coreTeamMembers"
import { ItemsWithCallout } from "../ItemsWithCallouts"
import { RichText } from "../RichText"
import { SocialLinks } from "../SocialLinks"

const CalloutChildren = ({ biography, ...socialLinks }) => (
  <Fragment>
    <RichText
      text={biography}
      sxOverrides={{ "> p:first-of-type": { mt: 0 } }}
    />
    <SocialLinks {...socialLinks} styles={{ "> a:first-of-type": { ml: 0 } }} />
  </Fragment>
)
CalloutChildren.propTypes = {
  biography: PrismicRichTextFieldPropType,
  socialLinks: PropTypes.shape(ContributorSocialLinkPropTypes),
}

const ItemChildren = ({ roles }) => <Text>{roles}</Text>
ItemChildren.propTypes = {
  roles: PropTypes.string,
}

export const CoreTeam = () => {
  const memberItems = coreTeamMembers().map(member => ({
    uid: member.uid,
    image: member.data.profile_picture,
    name: member.data.name,
    callout: <CalloutChildren {...member.data} />,
    children: <ItemChildren roles={member.data.roles} />,
  }))

  return (
    <div sx={{ my: 5 }}>
      <Heading
        as="h1"
        variant="headingOlive"
        sx={{
          textAlign: ["left", "left", "center"],
          ...theme.basePageStyles.contentContainer,
        }}
      >
        Our team
      </Heading>
      <ItemsWithCallout
        items={memberItems}
        itemsPerRow={{
          tablet: 4,
        }}
        itemStyle={{
          "> span:first-of-type": { variant: "text.subheading", mt: 3 },
          // Note: have to fix the width and height
          // to work around a limitation in Prismic previews
          // where fixed images are always fetched with a default 400px width
          // See https://github.com/prismicio/prismic-gatsby/blob/main/packages/gatsby-plugin-prismic-previews/docs/limitations.md#image-processing
          ".gatsby-image-wrapper": {
            width: "160px",
            height: "160px",
          },
        }}
        sliderOnMobile={true}
      />
    </div>
  )
}
