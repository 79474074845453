/** @jsx jsx */

import PropTypes from "prop-types"
import { jsx, Flex } from "theme-ui"

export const Callout = ({ children, styles }) => (
  <Flex
    sx={{
      my: 3,
      mx: [0, 0, 3],
      minWidth: "100%",
      display: "none",
      position: "relative",
      ...styles,
    }}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="white"
      width="25px"
      height="25px"
      sx={{
        position: "absolute",
        top: "-25px",
      }}
    >
      <polygon points="0,25 25,25 12.5,0" />
    </svg>
    <div
      sx={{
        bg: "white",
        p: 3,
        width: ["100%", "100%", "60%"],
      }}
    >
      {children}
    </div>
  </Flex>
)
Callout.propTypes = {
  children: PropTypes.node,
  styles: PropTypes.object,
}
