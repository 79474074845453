/** @jsx jsx */

import { jsx, Heading } from "theme-ui"
import PropTypes from "prop-types"

import { GatsbyImagePropType } from "../utils/propTypes"
import { Divider } from "./Divider"
import { DividerType } from "../hooks/divider"
import { ResponsiveImage } from "./ResponsiveImage"

export const Masthead = ({
  heading,
  showDivider,
  dividerType,
  image,
  otherChildren,
  containerStyles,
  mastheadStyles,
  headingStyles,
}) => {
  return (
    <ResponsiveImage
      isBackgroundImage={true}
      image={image}
      sx={{
        backgroundPosition: "top",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: ["200px", "200px", "250px", "300px"],
        mb: "40px",
        ...containerStyles,
      }}
      bgImgStyles={mastheadStyles}
    >
      <Heading
        as="h1"
        variant="headingMasthead"
        sx={{
          color: "white",
          textAlign: "center",
          mt: "100px",
          mb: 3,
          ...headingStyles,
        }}
      >
        {heading}
      </Heading>
      {showDivider ? (
        <Divider
          type={dividerType || DividerType.DEFAULT_WHITE}
          styles={{
            width: "60%",
          }}
        />
      ) : null}
      {otherChildren}
    </ResponsiveImage>
  )
}
Masthead.propTypes = {
  heading: PropTypes.string.isRequired,
  showDivider: PropTypes.bool,
  dividerType: PropTypes.oneOf(Object.values(DividerType)),
  image: PropTypes.shape({
    gatsbyImageData: GatsbyImagePropType,
  }),
  otherChildren: PropTypes.node,
  containerStyles: PropTypes.object,
  mastheadStyles: PropTypes.object,
  headingStyles: PropTypes.object,
}
