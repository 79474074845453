/** @jsx jsx */

import { useState } from "react"
import PropTypes from "prop-types"
import { jsx, Flex, Text } from "theme-ui"
import Slider from "react-slick"

import theme from "../gatsby-plugin-theme-ui"
import { GatsbyImagePropType } from "../utils/propTypes"
import { MediaMatcher } from "./MediaMatcher"
import { ResponsiveImage } from "./ResponsiveImage"
import { Callout } from "./Callout"
import { chunk } from "../utils/paginationUtils"

const ItemPropType = {
  uid: PropTypes.string,
  image: PropTypes.shape({
    alt: PropTypes.string,
    gatsbyImageData: GatsbyImagePropType,
  }),
  name: PropTypes.string,
  callout: PropTypes.node,
  children: PropTypes.node,
}

const Item = ({ name, image, children, onClick, styles }) => (
  <Flex
    role="button"
    onClick={onClick}
    sx={{
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
      "&:hover": {
        cursor: "pointer",
        opacity: 0.8,
      },
      m: 3,
      ...styles,
    }}
  >
    <ResponsiveImage image={image} sx={{ borderRadius: "50%", mb: 1 }} />
    <Text>{name}</Text>
    {children}
  </Flex>
)
Item.propTypes = {
  ...ItemPropType,
  children: PropTypes.node,
  onClick: PropTypes.func,
  styles: PropTypes.object,
}

const SliderItemsWithCallout = ({ items, itemStyle }) => {
  const [selectedItem, updateSelectedItem] = useState(null)

  const nodes = items.map(item => (
    <Item
      key={`item-${item.uid}`}
      {...item}
      onClick={() =>
        selectedItem !== item.uid
          ? updateSelectedItem(item.uid)
          : updateSelectedItem(null)
      }
      styles={{
        ...itemStyle,
        ...(selectedItem === item.uid
          ? {
              "> div:first-of-type": {
                borderStyle: "solid",
                borderWidth: "5px",
                borderColor: "oliveGreen",
              },
            }
          : {}),
      }}
    >
      {item.children}
      <Callout
        key={`callout-${item.uid}`}
        styles={{
          textAlign: "left",
          ml: ["20vw", "20vw"],
          mt: 6,
          width: "90vw",
          ...(selectedItem === item.uid ? { display: "flex" } : {}),
          "> svg": {
            left: ["calc(50% - 20vw)", "calc(50% - 16vw)"],
            transform: "translateX(100%)",
          },
        }}
      >
        {item.callout}
      </Callout>
    </Item>
  ))

  return (
    <Slider
      arrows={false}
      dots={false}
      infinite={false}
      autoplay={false}
      swipeToSlide={true}
      slidesToShow={1.5}
      sx={{
        "div.slick-list": {
          pr: "0 !important",
          pl: `${(100 - theme.mobilePageContentWidth) / 2}% !important`,
        },
      }}
    >
      {nodes}
    </Slider>
  )
}
SliderItemsWithCallout.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape(ItemPropType)),
  itemStyle: PropTypes.object,
}

const FlexItemsWithCallout = ({ items, itemsPerRow, itemStyle }) => {
  const [selectedItem, updateSelectedItem] = useState(null)

  const nodes = chunk(items, itemsPerRow)
    .map(itemRow => {
      const items = itemRow.map(item => (
        <Item
          key={`item-${item.uid}`}
          {...item}
          onClick={() =>
            selectedItem !== item.uid
              ? updateSelectedItem(item.uid)
              : updateSelectedItem(null)
          }
          styles={{
            minWidth: `calc(${100 / itemsPerRow}% - 32px)`,
            maxWidth: `calc(${100 / itemsPerRow}% - 32px)`,
            ...(selectedItem === item.uid
              ? {
                  "> div:first-of-type": {
                    borderStyle: "solid",
                    borderWidth: "5px",
                    borderColor: "oliveGreen",
                  },
                }
              : {}),
            ...itemStyle,
          }}
        >
          {item.children}
        </Item>
      ))
      const callouts = itemRow.map((item, i) => {
        const medianIndex = Math.floor(itemsPerRow / 2)
        const isFirstHalfItem = i < medianIndex
        const translateRatio = isFirstHalfItem
          ? i / itemsPerRow
          : (itemsPerRow - i - 1) / itemsPerRow
        const itemWidth = 100 / itemsPerRow

        return (
          <Callout
            key={`callout-${item.uid}`}
            styles={{
              justifyContent: isFirstHalfItem ? "flex-start" : "flex-end",
              ...(selectedItem === item.uid ? { display: "flex" } : {}),
              "> div": {
                transform: [
                  null,
                  null,
                  `translateX(${isFirstHalfItem ? "" : "-"}${
                    100 * translateRatio
                  }%)`,
                ],
              },
              "> svg": {
                ...(isFirstHalfItem
                  ? {
                      left: `calc(${itemWidth / 2}% + ${
                        100 * translateRatio
                      }%)`,
                      transform: `translateX(-100%)`,
                    }
                  : {
                      right: `calc(${itemWidth / 2}% + ${
                        100 * translateRatio
                      }%)`,
                    }),
              },
            }}
          >
            {item.callout}
          </Callout>
        )
      })
      return items.concat(callouts)
    })
    .flat()

  return (
    <Flex sx={{ flexWrap: "wrap", ...theme.basePageStyles.contentContainer }}>
      {nodes}
    </Flex>
  )
}
FlexItemsWithCallout.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape(ItemPropType)),
  itemsPerRow: PropTypes.number,
  itemStyle: PropTypes.object,
}

export const ItemsWithCallout = ({
  items,
  itemsPerRow,
  itemStyle,
  sliderOnMobile,
}) => {
  return (
    <MediaMatcher.ServerRender predicted="mobilePortrait">
      <MediaMatcher.Matcher
        mobilePortrait={
          sliderOnMobile ? (
            <SliderItemsWithCallout items={items} itemStyle={itemStyle} />
          ) : (
            <FlexItemsWithCallout
              itemsPerRow={itemsPerRow.mobilePortrait}
              items={items}
              itemStyle={itemStyle}
            />
          )
        }
        mobileLandscape={
          sliderOnMobile ? (
            <SliderItemsWithCallout items={items} itemStyle={itemStyle} />
          ) : (
            <FlexItemsWithCallout
              itemsPerRow={itemsPerRow.mobileLandscape}
              items={items}
              itemStyle={itemStyle}
            />
          )
        }
        tablet={
          <FlexItemsWithCallout
            itemsPerRow={itemsPerRow.tablet}
            items={items}
            itemStyle={itemStyle}
          />
        }
      />
    </MediaMatcher.ServerRender>
  )
}
ItemsWithCallout.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape(ItemPropType)),
  itemsPerRow: PropTypes.shape({
    mobilePortrait: PropTypes.number,
    mobileLandscape: PropTypes.number,
    tablet: PropTypes.number,
  }),
  itemStyle: PropTypes.object,
  sliderOnMobile: PropTypes.bool,
}
